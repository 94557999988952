export const firebaseConfig = {
  apiKey: "AIzaSyCU_7eqiiDjpECYuEh2mwQM-zn0rFT_YSk",
  authDomain: "michiganmed.firebaseapp.com",
  databaseURL: "https://michiganmed.firebaseio.com",
  projectId: "michiganmed",
  storageBucket: "michiganmed.appspot.com",
  messagingSenderId: "393241362570",
  appId: "1:393241362570:web:091e083243f5ee93e66d73"
};

export const vapidKey = `BDOeXG63RdhOZg1U6OW0NK_mogXPRkORNUL6vLfU5Q2__wHEO90VahTqQYVV8GXrbOfV01Wkh7BMEGX2pjgEEX0`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}